import styled from "styled-components"

export const Main = styled.div`
  margin: 3.0288rem 0 4.0281rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5.9375rem;
  min-height: calc(100vh - 20.3069rem);

  @media screen and (max-width: 1020px) {
        padding: 1rem 2.5rem;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        gap: ${({ theme }) => theme.spacing.m};
    }
`
export const InfoWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing.l};
  max-width: 29.6875rem;
`

export const IntroWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.gray.text_base};

  h1 {
    font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeXL};
    font-weight: ${({ theme }) => theme.fontWeight.fontWeight.fontWeightBold};
  }

  p, span {
    font-weight: ${({ theme }) => theme.fontWeight.fontWeight.fontWeightNormal};
  }

  .caption {
    display: inline-block;
    font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeSM};
    margin: ${({ theme }) => theme.spacing.s} 0 1.125rem 0;
    line-height: 1.3125rem;
  }

  .resend {
    font-size: ${({ theme }) => theme.fontSize.fontSize.fontSizeXS};
    color: #263238;
    line-height: 1.125rem;

    a {
        color: ${({ theme }) => theme.colors.secondary.health_green};
        text-decoration: underline;
    }
  }
`

export const ImageWrapper = styled.section`
  max-width: 34.125rem;
  max-height: 24.8125rem;

  .main-image {
    width: 100%;
    height: 100%;
  }
`