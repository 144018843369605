import React from "react"
import Footer from "../../components/profissionaisSaude/Footer"
import Header from "../../components/profissionaisSaude/Header"
import Button from "../../components/profissionaisSaude/Inputs/Button"
import Image from "../../assets/images/reset-password-confirmation.svg"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { Container } from "../../components/compartilhados/Container"
import { Main, InfoWrapper, IntroWrapper, ImageWrapper } from "../../styles/saude/redefinicao-senha-confirmacao"

export default function RedefinicaoSenhaConfirmacao() {
    const location = useLocation()
    const email  = location.state?.email ?? ''
    
    return (
        <Container>
            <Header />
            <Main>
                <InfoWrapper>
                    <IntroWrapper>
                        <h1>Verifique seu e-mail para redefinir a senha</h1>
                        <p className="caption">Enviamos um link de redefinição para o e-mail <b>{email}</b>.</p>
                        <span className="resend">Não recebeu o e-mail? <Link to="/saude/redefinicao-senha">Reenviar</Link>.</span>
                    </IntroWrapper>
                    <Button
                        title="Voltar a tela inicial"
                        link="/saude/painel-inicial"
                    />
                </InfoWrapper>
                <ImageWrapper>
                    <Image className="main-image" />
                </ImageWrapper>
            </Main>
            <Footer />
        </Container>
    )
}
