import styled from "styled-components"

//Container geral que encapsula todo o conteúdo das páginas (header, main e footer)
export const Container = styled.div `
    padding: 0 ${({theme}) => theme.spacing.layout3xl};
    max-width: ${({theme}) => theme.spacing.containerMaxWidth};
    margin: auto;

    @media screen and (max-width: 1200px) {
        padding: 0 ${({theme}) => theme.spacing.layoutxl};
    }

    @media screen and (max-width: 1020px) {
        padding: 0;
        gap: 0;
        justify-content: center;
    }
`